.ant-tabs-tab.ant-tabs-tab-active{
 /* background-color: #FEE8E4 !important; */
 background-color: #ffffff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
 color: #000000 !important;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
 color: #ffffff ;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn:focus {
 color: #ffffff ;
}

.ant-tag-default {
 background: #f6ffed00;
 border-color: #b7eb8f00;
}

.ant-tag-success{
 background: #f6ffed00;
 border-color: #b7eb8f00;
}

.ant-tag-error {
 background: #f6ffed00;
 border-color: #b7eb8f00;
}

.PhoneInputInput {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.css-dev-only-do-not-override-mxhywb.ant-form legend { width: 0%;}